/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import { setSession } from '../utils/jwt';
import conObj from '../utils/connection-assistant';
import { getCountryFromUrl } from '../utils/formatString';
import { fRequestData } from '../utils/formatObject';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: !!localStorage.getItem(`accessToken-${getCountryFromUrl()}`),
  isInitialized: false,
  user: localStorage.getItem(`userInfo-${getCountryFromUrl()}`)
    ? JSON.parse(localStorage.getItem(`userInfo-${getCountryFromUrl()}`))
    : null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    console.log(`Enter INITIALIZE`);
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  getAccountProfile: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const country = getCountryFromUrl();

      const search = window.location.search;

      const params = new URLSearchParams(search);

      const referralCode = params.get('aff');

      const pathname = window.location.pathname;

      const appRedirect = params.get('redirect');

      /**
       * This is special checking for india payment gateway
       * as they dont allow to put search param in the callback url
       */
      const pathParams = extractPathParams(pathPattern, pathname);

      if (referralCode) {
        localStorage.setItem('referralCode', referralCode);
      }

      if (pathParams?.orderId) {
        localStorage.setItem('orderId', pathParams?.orderId);
      }

      if (appRedirect) {
        localStorage.setItem('appRedirect', appRedirect);
      }

      await getAccountProfile();
    } catch (err) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  const login = async (data) => {
    const country = getCountryFromUrl();
    localStorage.removeItem(`accessToken-${country}`);
    localStorage.removeItem(`userInfo-${country}`);

    const response = await conObj.post('login.json', {
      'user-username': data.username,
      'user-password': data.password,
    });

    const {
      token,
      data: { first_name, username, full_name, last_name, email },
    } = response.data.data;

    if (token) {
      setSession(token);
      initialize();
      // await getAccountProfile('LOGIN');
    } else throw new Error(response.data?.data?.msg);
  };

  const register = async ({ username, profileNoHp, email, fullName, password, referralCode, birthday }) => {
    const country = getCountryFromUrl();

    localStorage.removeItem(`accessToken-${country}`);
    localStorage.removeItem(`userInfo-${country}`);

    const payload = {
      'user-username': profileNoHp?.replace(/\D/g, ''),
      'user-password': password,
      'user_profile-no_hp': profileNoHp?.replace(/\D/g, ''),
      'user-email': email,
      'user-full_name': fullName,
      'user-referral_code': referralCode,
      'user_profile-birthday': birthday,
    };

    const response = await conObj.post('register.json', fRequestData(payload));

    const { token, data } = response.data.data;

    localStorage.setItem('registerPixelId', data?.tracking_pixel_id);

    setSession(token);

    await getAccountProfile('REGISTER');
    // window.location.reload();
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const getAccountProfile = async (dispatchType = 'INITIALIZE') => {
    try {
      const country = getCountryFromUrl();

      const accessToken = localStorage.getItem(`accessToken-${country}`);
      if (accessToken) {
        // const response = await conObj.post('profile.json');

        // const responseData = response.data?.data?.data;

        // const _user = { ...responseData, displayName: responseData?.first_name || '' };

        // localStorage.setItem(`userInfo-${country}`, JSON.stringify(_user));

        dispatch({
          type: dispatchType,
          payload: {
            isAuthenticated: true,
            // user: _user,
            user: {},
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        getAccountProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const pathPattern = '/:domain/payment/:merchantName/:orderId/:product';

function extractPathParams(pattern, pathname) {
  const patternParts = pattern.split('/');
  const pathParts = pathname.split('/');
  const params = {};

  patternParts.forEach((part, index) => {
    if (part.startsWith(':')) {
      const paramName = part.slice(1);
      params[paramName] = pathParts[index];
    }
  });

  return params;
}

export { AuthContext, AuthProvider };
