import { useNavigate, useParams } from 'react-router';

const useRedirect = () => {
  const navigate = useNavigate();

  const params = useParams();

  const customNavigate = (url, _state = {}) => {
    console.log(`url = ${url}`);
    console.log(`_state = ${_state}`);
    if (url === 'back') {
      navigate(-1);
    } else if (url && params?.domain) {
      console.log(`enter valid URL = /${params.domain}/${url.charAt(0) === '/' ? url?.replace('/', '') : url}`);
      navigate(`/${params.domain}/${url.charAt(0) === '/' ? url?.replace('/', '') : url}`, _state);
    } else {
      navigate('404');
    }
  };

  return customNavigate;
};

export default useRedirect;
